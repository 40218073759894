<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card icon="mdi-chart-box-outline">
          <template v-slot:after-heading>
            <div class="font-weight-light card-title mt-2">
              {{ $t('sondages_page.mes_sondages') }}
            </div>
          </template>

          <v-select
            v-if="info"
            v-model="conférence"
            :items="info.conférences"
            :label=" $t('sondages_page.conference') "
            item-text="nomConférence"
            return-object
          />

          <v-select
            v-if="conférence"
            v-model="rapportSondage"
            :items="conférence.rapportsSondage"
            :label=" $t('sondages_page.sondage') "
            item-text="nomSondage"
            return-object
          />
          <v-row v-if="rapportSondage">
            <v-spacer />
            <v-btn
              text
              @click="onImprimerRapport()"
            >
              <v-icon left>
                mdi-printer
              </v-icon>
              {{ $t('sondages_page.imprimer_raport') }}
            </v-btn>
          </v-row>
          <div
            v-if="rapportSondage"
            id="printMe"
          >
            <div>
              <v-card
                v-for="rapport in conférence.rapportsSondage"
                :key="rapport.nomSondage"
              >
                <v-card-text>
                  <div v-html="rapport.descriptionSondage" />
                </v-card-text>
                <v-card-title class="pb-5">
                  <h2>{{ rapport.nomSondage }}</h2>
                </v-card-title>

                <v-card-text>
                  <div
                    style="font-size:1.3em"
                    class="pb-6"
                  >
                    <strong>{{ $t('sondages_page.conference') }} :</strong> {{ conférence.nomConférence }}
                  </div>
                  <div
                    style="font-size:1.3em"
                    class="pb-6"
                  >
                    <strong>{{ $t('sondages_page.repondant') }} :</strong> {{ $store.state.userInfo.nom }}, {{ $store.state.userInfo.prenom }}
                  </div>
                  <div
                    v-for="question in rapport.listeQuestions"
                    :key="question.idQuestion"
                  >
                    <hr>
                    <div
                      style="padding:1em 0 1em 0"
                      v-html="question.texteQuestion"
                    />
                    <v-row
                      v-for="choix in question.listeChoix"
                      :key="choix.idChoix"
                      :class="classeChoix(question,choix)"
                    >
                      <v-col cols="5">
                        <div v-html="choix.texte" />
                      </v-col>
                      <v-col cols="4">
                        <div> {{ $t('sondages_page.reponses') }} : {{ choix.nombreRéponses }}</div>
                        <div> {{ $t('sondages_page.moyenne') }} : {{ choix.pourcent }} % </div>
                      </v-col>
                      <v-col cols="3">
                        <div v-if="choix.sélection">
                          {{ $t('sondages_page.votre_reponse') }}
                        </div>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </div>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
.rapport-bonChoix{
  color:green;
}
.rapport-mauvaisChoix{
  color:red;
}
</style>

<script>
  import Vue from 'vue'
  import restApiService from '@/services/restApiService.js'
  import VueHtmlToPaper from 'vue-html-to-paper'

  const options = {
    name: '_blank',
    specs: [
      'fullscreen=yes',
      'titlebar=yes',
      'scrollbars=yes',
    ],
    styles: [
      'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
      'https://unpkg.com/kidlat-css/css/kidlat.css',
    ],
  }

  Vue.use(VueHtmlToPaper, options)

  export default {
    data: () => ({
      usager: {
        roles: 'user',
        adresse: {
        },
      },
      motPasse: '',
      motPasseValidation: '',
      info: undefined,
      conférence: undefined,
      rapportSondage: undefined,
    }),
    mounted () {
      const param = {
        idConference: null,
        idEvenement: this.$store.state.evenementActif?.id,
        idUsager: this.$store.state.userInfo.id,
      }
      restApiService
        .put('/api/sondage/rapport', param)
        .then((result) => {
          this.info = result.data
          if (this.info.conférences.length > 0) {
            this.conférence = this.info.conférences[0]
            if (this.conférence.rapportsSondage.length > 0) {
              this.rapportSondage = this.conférence.rapportsSondage[0]
            }
          }
        })
        .catch((erreur) => alert(erreur))
    },
    methods: {
      classeChoix (question, choix) {
        const neutre = question.listeChoix.find(c => c.points > 0) === undefined
        if (neutre) { return '' }
        if (choix.points > 0) {
          return 'rapport-bonChoix text-success'
        }
        return 'rapport-mauvaisChoix text-danger'
      },
      onImprimerRapport () {
        this.$htmlToPaper('printMe')
      },
    },
    //
  }
</script>
